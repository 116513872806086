import axios from 'axios';
import { loginErrroMessage } from './lib/functions';



window.addEventListener('load', () => {
  const loginBtn = document.getElementById('loginBtn');

  loginBtn.onclick = () => {
    const mailAddress = document.getElementById('mailAddress').value,
          password    = document.getElementById('password').value;

    if( !mailAddress ) {
      loginErrroMessage('メールアドレスを入力してください');
      return;
    } else {
      if( /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/.test(mailAddress) !== true ) {
        loginErrroMessage('正しいメールアドレスを入力してください');
        return;
      }
    };

    if( !password ) {
      loginErrroMessage('パスワードを入力してください');
      return;
    };

    // 認証
    axios.post('/xhr/auth.php', {
      mailAddress: mailAddress,
      password: password
    })
    .then(function (res) {
      if( res.data ){
        loginErrroMessage(res.data);
        return;
      }

      window.location.href = 'index.php';
      return;
    })
    .catch(function (error) {
      console.log(error);
    });

  };
});
