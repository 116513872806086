// ログイン画面でエラーメッセージを出力します
export const loginErrroMessage = (str) => {
  const errorElement = document.getElementById('loginError');
  errorElement.innerHTML = `<span class="login-error-message">${str}</span>`;
  return;
}



/**
*** ローディング画面をアクティブにします
**/
export const activeLoading = () => {
  const loadingWrapper = document.getElementById('loadingWrapper');
  // ない場合はなにもしない
  if( !loadingWrapper ) return;
  loadingWrapper.setAttribute('aria-disabled', 'false');
};



/**
*** ローディング画面を非アクティブにします
**/
export const deactiveLoading = () => {
  const loadingWrapper = document.getElementById('loadingWrapper');
  // ない場合はなにもしない
  if( !loadingWrapper ) return;
  loadingWrapper.setAttribute('aria-disabled', 'true');
};



/**
*** NodeList かチェック
**/
export const isNodeList = (el) => {
  return Object.prototype.toString.call(el) === '[object RadioNodeList]';
}



/**
*** オブジェクト型かチェック
**/
export const isObject = (el) => {
  return el !== null && typeof el === 'object';
}



/**
*** オブジェクトの値がすべて空であるかチェックします
**/
export const checksAllObjectValue = (obj) => {
  const values = Object.values(obj);
  for( const val of values ) {
    if( val !== '' && val !== null && val !== undefined ) return false;
  }
  return true;
}



/**
*** 指定したプロパティを要素から取得する
**/
export const getStyle = (el, styleProp) => {
  let defaultView = ( el.ownerDocument || document ).defaultView;
  styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
  return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
};
